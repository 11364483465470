import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "../components/Home";
// import Login from "../components/Login";
// import SignUp from "../components/SignUp";
// import { AuthGuard, GuestGuard } from "./Guard";
import { MainLayout } from "./MainLayout";
// import { CommonLayout } from "./CommonLayout";

export function Routes() {
  // const loginRoutes = {
  //   path: "/",
  //   element: (
  //     <GuestGuard>
  //       <CommonLayout />
  //     </GuestGuard>
  //   ),
  //   children: [
  //     { path: "/", element: <Login /> },
  //     { path: "/login", element: <Login /> },
  //     { path: "/signup", element: <SignUp /> },
  //   ],
  // };

  const mainRoutes = {
    path: "/",
    element: (
      // <AuthGuard>
        <MainLayout />
      // </AuthGuard>
    ),
    children: [{ path: "/", element: <Home /> }],
  };

  const routeResult = useRoutes([ mainRoutes]);

  return <div>{routeResult}</div>;
}
