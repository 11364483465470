import PropTypes from 'prop-types';

// third-party
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const InnerSnackbarUtilsConfigurator = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};

function ToastCloseButton({ onClick }) {
  return (
    <IconButton disableRipple size="small" aria-label="close" color="inherit" onClick={onClick}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

ToastCloseButton.propTypes = {
  onClick: PropTypes.func,
};

InnerSnackbarUtilsConfigurator.propTypes = {
  setUseSnackbarRef: PropTypes.func,
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

setUseSnackbarRef.propTypes = {
  useSnackbarRefProp: PropTypes.any,
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

export default function toast(message, options = {}) {
  const { closeSnackbar, enqueueSnackbar } = useSnackbarRef;

  const defaultOptions = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    preventDuplicate: true,
  };

  const action =
    options.variant && !options.noCloseButton ? { action: (key) => <ToastCloseButton onClick={() => closeSnackbar(key)} /> } : {};

  delete options.noCloseButton;

  enqueueSnackbar(message, {
    ...defaultOptions,
    ...action,
    ...options,
  });
}
