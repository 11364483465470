/* eslint-disable import/no-anonymous-default-export */
export default {
  API_HOST: process.env.REACT_APP_HOST || '',
  API_BASE: 'api/',
  API_VERSION: 'v1',
  API: {
    CHECK_DB_CREDENTIALS: '/check_db_credentials',
    GET_ALL_CONNECTIONS: '/get_all_connections',
    FIND_DB_INFO: '/find_db_info',
    GET_ALL_ACTIVE_SESSIONS:'/get-all-active-sessions',
    GET_ALL_CHATS:'/get-all-chats',
    GET_RESPONSE:'/get-response',
  },
};
