import React, { useEffect, useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import AppBarComponent from "../components/AppBarComponent";
import DrawerComponent from "../components/DrawerComponent";
import SettingsDialog from "../components/SettingsDialog";
import { useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useAuth } from "../navigation/Provider";
import { checkDbCred, getAllActiveSessions, getAllConnections, getConnectionById } from "../services/authService";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const MainLayout = () => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [connections, setConnections] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [connectionDetails, setConnectionDetails] = useState({});
  const { dbConnect, dbDisconnect, isConnected } = useAuth();
  const id = localStorage.getItem('id');
  const location = useLocation();


  const defaultValues = {
    host: "",
    port: "",
    username: "",
    password: "",
    database: "",
    dialect: "postgres",
  };

  const methods = useForm({ defaultValues });

  const handleDrawerOpen = () => setOpenDrawer(true);
  const handleDrawerClose = () => setOpenDrawer(false);
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const onSubmit = async (formData) => {
    try {
      const response = await checkDbCred(formData);
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem("id", response.data?.data?.id);
        localStorage.setItem("username", response.data?.data?.username);
        await dbConnect();
        handleDialogClose();
        methods.reset();
      } else {
        console.error('Database credentials are invalid:', response.message);
      }
    } catch (error) {
      console.error('Error checking database credentials:', error);
    }
  };

  const getConnectionValueById = async (id) => {
    try {
      const response = await getConnectionById(id);
      methods.reset(response.data.data || defaultValues);  // Ensure default values are used if response.data.data is undefined
      handleDialogOpen();
    } catch (error) {
      console.error('Error fetching connection by ID:', error);
    }
  };


  useEffect(() => {
    // Extract the token from the query parameters
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    // If the token exists, store it in sessionStorage
    if (token) {
      sessionStorage.setItem('authToken', token);
    }
  }, [location.search]);

  useEffect(() => {
    if (id && isConnected) {
      getConnectionById(id).then((response) => {
        setConnectionDetails(response?.data?.data);
      });
      getAllActiveSessions(id).then((response) => {
        const data = response.data?.data;
        // Sort the data by the created_at timestamp in descending order
        data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setSessions(data);
        if (!localStorage.getItem('sessionId')) {
        // Get the ID of the latest timestamp
        const latestId = data[0].id;
          // Save the ID in localStorage
          localStorage.setItem('sessionId', latestId);
        }

      });
    } else {
      getAllConnections().then(response => {
        setConnections(response.data.data || []);  // Ensure connections is never undefined
      }).catch(error => {
        console.error('Error fetching all connections:', error);
      });
    }
  }, [id, isConnected]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBarComponent open={openDrawer} handleDrawerOpen={handleDrawerOpen} />
      <DrawerComponent
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        handleDialogOpen={handleDialogOpen}
        dbDisconnect={dbDisconnect}
        isConnected={isConnected}
        connections={connections}
        sessions={sessions}
        connectionDetails={connectionDetails}
        getConnectionValueById={getConnectionValueById}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
      <SettingsDialog
        open={openDialog}
        handleClose={handleDialogClose}
        methods={methods}
        onSubmit={onSubmit}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
    </Box>
  );
}