import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import StorageIcon from "@mui/icons-material/Storage";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ProLogo from '../assets/images/progpt_logo/pro-logo-white.png';

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  background: "#00B0FF",
  color: "#ffffff",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "20px 0px 8px 20px",

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerComponent = ({
  open,
  handleDrawerClose,
  handleDialogOpen,
  dbDisconnect,
  isConnected,
  connections,
  sessions,
  connectionDetails,
  getConnectionValueById,
}) => {
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  useEffect(() => {
    // Retrieve the stored session ID from localStorage when the component mounts
    setTimeout(() => {
      const storedSessionId = localStorage.getItem("sessionId");
      setSelectedSessionId(storedSessionId);
    }, 500);
  }, [isConnected, selectedSessionId]);

  const handleSessionClick = (id) => {
    // Update the selected session ID state
    setSelectedSessionId(id);
    // Update the localStorage with the new session ID
    localStorage.setItem("sessionId", id);
    // Call the function to get session by ID
    // getSessionById(id);
  };

  const handleTimeZone = (timestamp) => {
    // Convert the string to a Date object
    const date = new Date(timestamp);

    // Extract the day, month, year, hours, minutes, and seconds in the user's local timezone
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Format the date and time as dd/mm/yyyy hh:mm:ss
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
        "& .MuiDrawer-paper": {
          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <DrawerHeader>
        <img src={ProLogo} alt="Your SVG" width={140} />
      </DrawerHeader>
      {/* <Divider sx={{ boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)" }} /> */}
      {isConnected ? (
        <Card sx={{ m: 2, p: 1, minHeight: "350px" }}>
          <CardContent>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Typography sx={{fontSize:"18px"}} component="div">
                Connected to Database
              </Typography>
            </Box>
            {connectionDetails && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Database:</strong> {connectionDetails.database}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Dialect:</strong> {connectionDetails.dialect}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Host:</strong> {connectionDetails.host}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Port:</strong> {connectionDetails.port}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    <strong>Username:</strong> {connectionDetails.username}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
          <CardActions>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <Button
                variant="contained"
                color="error"
                sx={{
                  height: '36px',
                  fontSize: '14px',
                }}
                endIcon={<StorageIcon />}
                onClick={dbDisconnect}
              >
                Disconnect
              </Button>
            </Box>
          </CardActions>
        </Card>
      ) : (
        <Button
          variant="contained"
          sx={{
            margin: '32px 20px 0px',
            height: '36px',
            fontSize: '14px',
            backgroundColor: '#f5f5f5',
            color: '#00B0FF',
            '&:hover': {
              backgroundColor: '#f5f5f5',
              color: '#00B0FF',
            },
          }}
          endIcon={<StorageIcon />}
          onClick={handleDialogOpen}
        >
          Connect
        </Button>
      )}
      {!isConnected ? (
        <>
          {connections?.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography
                variant="h6"
                sx={{ mx: 2, my: 1, textAlign: "center" }}
              >
                Recent Connections
              </Typography>
              <List>
                {connections.map((conn, index) => (
                  <ListItem
                    key={conn.id}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => getConnectionValueById(conn.id)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <StorageIcon sx={{ color: "#fff" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={conn.database}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </>
      ) : (
        <>
          {sessions?.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography
                variant="h6"
                sx={{ mx: 2, my: 1, textAlign: "center" }}
              >
                Recent Chats
              </Typography>
              <List sx={{ overflow: "auto" }}>
                {sessions.map((session, index) => (
                  <ListItem
                    key={session.id}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        backgroundColor:
                          session.id === selectedSessionId
                            ? "white"
                            : "inherit", // Highlight the selected session
                       color:
                            session.id === selectedSessionId
                              ? "black"
                              : "inherit",
                      }}
                      onClick={() => handleSessionClick(session.id)}
                    >
                      {/* <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <StorageIcon />
                    </ListItemIcon> */}
                      <ListItemText
                        primary={session.last_message}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </>
      )}
    </Drawer>
  );
};

export default DrawerComponent;
