import React from "react";
import { Routes as AdvanceRoutes } from "./navigation/Routes";
import { Provider } from "./navigation/Provider";
import Notistack from "./components/third-party/Notistack";

function App() {
  return (
    <Notistack>
      <Provider>
        <AdvanceRoutes />
      </Provider>
    </Notistack>
  );
}

export default App;
