// AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const Provider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(JSON.parse(localStorage.getItem("isConnect")) || false);

  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );

  const navigate = useNavigate();

  const login = () => {
    setIsAuthenticated(true);
    localStorage.setItem("isLoggedIn", true);
    navigate("/home");
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.setItem("isLoggedIn", false);
    navigate("/login");
  };

  const dbConnect = () => {
    setIsConnected(true);
    localStorage.setItem("isConnect", true);
  };

  const dbDisconnect = () => {
    setIsConnected(false);
    localStorage.setItem("isConnect", false);
    localStorage.removeItem("id");
    localStorage.removeItem("sessionId");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, isConnected, dbConnect, dbDisconnect }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
