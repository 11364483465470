import constants from "../constants";
import { httpService } from "../utils";

const checkDbCred = ({ host, port, database, username, password, dialect }) => {
  return httpService().post(constants.API.CHECK_DB_CREDENTIALS, {
    host,
    port,
    database,
    username,
    password,
    dialect,
  });
};

const getAllConnections = () => {
  return httpService().get(constants.API.GET_ALL_CONNECTIONS);
};

const getConnectionById = (id) => {
  return httpService(undefined, undefined).get(
    `${constants.API.FIND_DB_INFO}/${id}`
  );
};

const getAllActiveSessions = (databaseId) => {
  return httpService(undefined, undefined).get(
    `${constants.API.GET_ALL_ACTIVE_SESSIONS}/${databaseId}`
  );
};

const getAllChats = (sessionId) => {
  return httpService(undefined, undefined).get(
    `${constants.API.GET_ALL_CHATS}/${sessionId}`
  );
};

const getResponse = ({query, session_id}) => {
  return httpService().post(constants.API.GET_RESPONSE, {query, session_id});
};

export {
  getConnectionById,
  checkDbCred,
  getAllConnections,
  getAllActiveSessions,
  getAllChats,
  getResponse,
};
