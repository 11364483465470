import * as React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Grid, InputAdornment, IconButton, MenuItem } from "@mui/material";
import { FormProvider, RHFSelect, RHFTextField,  } from '../components/hook-form';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

const SettingsDialog = ({ open, handleClose, methods, onSubmit, showPassword, setShowPassword }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    PaperProps={{
      sx: { minWidth: '750px' },
    }}
  >
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: 28 }}>Settings</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3, fontSize: 20 }}>
          This is a demo of chat with database. Connect to database and start chatting.
        </DialogContentText>
        <Grid container spacing={4} sx={{ p: 1 }} >
        <Grid item md={12}>
            <RHFSelect
              name="dialect"
              label="Dialect"
              fullWidth
              required
            >
              <MenuItem value="postgres">Postgres</MenuItem>
              <MenuItem value="mysql">MySQL</MenuItem>
              <MenuItem value="sqlite">SQLite</MenuItem>
              <MenuItem value="mssql">MSSQL</MenuItem>
            </RHFSelect>
          </Grid>
          <Grid item md={12}>
            <RHFTextField name="host" label="Host" fullWidth required />
          </Grid>
          <Grid item md={12}>
            <RHFTextField name="port" label="Port" fullWidth required />
          </Grid>
          <Grid item md={12}>
            <RHFTextField name="username" label="Username" fullWidth required/>
          </Grid>
          <Grid item md={12}>
            <RHFTextField
              required
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={12}>
            <RHFTextField name="database" label="Database" fullWidth required />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Button type="submit" variant="contained" sx={{ mb: 2 }}>Connect</Button>
      </DialogActions>
    </FormProvider>
  </Dialog>
);

export default SettingsDialog;
