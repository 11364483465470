import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

const GraphComponent = ({ graphData }) => {
  // Destructure and add fallback values for potential null/undefined properties
  const {
    keys = [],
    values = [],
    xlabel = '',
    ylabel = '',
    graph_title = 'Graph'
  } = graphData || {};

  // Create the data array only if keys and values are available and have the same length
  const data = keys.length === values.length ? keys.map((key, index) => ({
    key: key,
    count: values[index],
  })) : [];

  return (
    <div style={{ width: '100%', height: 400, marginTop: '20px' }}>
      <h3>{graph_title}</h3>
      <BarChart
        width={580}
        height={300}
        data={data}
        margin={{ top: 20, right: 30, bottom: 5, left: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" label={{ value: xlabel, position: 'insideBottomRight', offset: 0 }} />
        <YAxis label={{ value: ylabel, angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill='#1E2A5E' />
      </BarChart>
    </div>
  );
};

export default GraphComponent;
