/* eslint-disable import/no-anonymous-default-export */
import toast from "./ToastNotistack";

export default {
  requestHandler(request) {
    return request;
  },
  errorHandler(error) {
  console.log('error :', error);
  toast(`${error.message}`, { variant: 'error' });
    if (error && error?.response?.status && error.response.status === 401) {
      // call sign out here
      // AuthService.signOut();
    }
    return {
      error: {
        ...(error.response?.data || error.response || error),
        msg: error.response?.data?.message || error.response?.errors?.[0]?.message || error.response?.message || 'Something Went Wrong',
      },
      status: error.response?.status,
    };
  },
  successHandler(response) {
    return {
      status: response.status,
      data: response?.data,
      ...(response.data.message && { message: response.data.message }),
    };
  },
};
