import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Paper,
  Grid,
  IconButton,
  InputAdornment,
  Avatar,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person"; // Icon for user
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import DownloadIcon from "@mui/icons-material/Download";

import "./Home.css";
import { useAuth } from "../navigation/Provider";
import GraphComponent from "./GraphComponent"; // Import your Graph component
import chatbotresize from "../assets/images/progpt_logo/chatbotresize.png";
import { getAllChats, getResponse } from "../services/authService";

const HomePage = () => {
  const [prompt, setPrompt] = useState("");
  const [responses, setResponses] = useState([]);
  const { isConnected } = useAuth();
  const [isConnectedLocal, setIsConnectedLocal] = useState(
    () => JSON.parse(localStorage.getItem("isConnect")) || false
  );
  const [sessionId, setSessionId] = useState(localStorage.getItem("sessionId"));

  const lastMessageRef = useRef(null); // Reference to the last message

  const handleStorageChange = () => {
    setIsConnectedLocal(JSON.parse(localStorage.getItem("isConnect")));
    setSessionId(localStorage.getItem("sessionId"));
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (sessionId) {
      getAllChats(sessionId).then((response) => {
        const data =
          response?.data?.data?.map((item) => {
            if (item.is_graph && item.graph_data) {
              // Parse graph_data JSON string to an object
              try {
                item.graph_data = JSON.parse(item.graph_data);
              } catch (error) {
                console.error("Error parsing graph_data:", error);
                item.graph_data = null; // Handle parsing error if needed
              }
            }
            return item;
          }) || [];
        setResponses(data);
      });
    }
  }, [sessionId]);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to the last message
    }
  }, [responses]);

  useEffect(() => {
    const originalSetItem = localStorage.setItem;
    const originalRemoveItem = localStorage.removeItem;

    localStorage.setItem = function (key, value) {
      originalSetItem.apply(this, arguments);
      handleStorageChange();
    };

    localStorage.removeItem = function (key) {
      originalRemoveItem.apply(this, arguments);
      handleStorageChange();
    };

    return () => {
      localStorage.setItem = originalSetItem;
      localStorage.removeItem = originalRemoveItem;
    };
  }, []);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const handlePromptSubmit = async () => {
    if (prompt.trim() !== "") {
      try {
        const userQuery = prompt.trim();
        const currentTime = new Date().toLocaleTimeString();

        const tempResponse = {
          user_query: userQuery,
          bot_response: "",
          time: currentTime,
          is_graph: false, // Default to false
          graph_data: null, // Default to null
          csv_url: "", // Default to an empty string
          is_csv: false, // Default to false
          sql_query: "", // Default to an empty string
        };

        setResponses((prevResponses) => [...prevResponses, tempResponse]);

        setPrompt("");

        const { data } = await getResponse({
          query: userQuery,
          session_id: sessionId,
        });
        console.log("API response data:", data);

        if (data && data.data !== undefined) {
          const botResponse = data.data;

          // Check if the response includes graph data
          const isGraph = data.is_graph === true;
          const graphData = isGraph ? data.graph_data : null;

          // Check if the response includes CSV data
          const isCsv = data.is_csv === true;
          const csvUrl = isCsv ? data.csv_url : "";

          // Check if the response includes SQL query
          const sqlQuery = data.sql_query || "";

          setResponses((prevResponses) => {
            const newResponses = [...prevResponses];
            const lastResponse = newResponses[newResponses.length - 1];

            lastResponse.bot_response = botResponse;
            lastResponse.is_graph = isGraph;
            lastResponse.graph_data = graphData;
            lastResponse.is_csv = isCsv;
            lastResponse.csv_url = csvUrl;
            lastResponse.sql_query = sqlQuery;

            return newResponses;
          });
        } else {
          setResponses((prevResponses) => [...prevResponses]);
        }
      } catch (error) {
        console.error("Error fetching response:", error);
      }
    }
  };

  if (!isConnectedLocal && !isConnected) {
    return (
      <Box className="no-db-container">
        <Typography variant="h4">No database connected</Typography>
      </Box>
    );
  }

  return (
    <Box className="home-page-container">
      <Grid container spacing={2} className="responses-container">
        {responses.length === 0 ? (
          <Grid item md={12}>
            <Typography variant="h6" align="center">
              No messages yet. Start the conversation!
            </Typography>
          </Grid>
        ) : (
          responses.map((response, index) => (
            <React.Fragment key={index}>
              <Grid container item md={12}>
                <Grid container item alignItems="flex-start" spacing={1}>
                  <Grid
                    item
                    xs
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Paper className="user-message">
                      <Typography>
                        {response?.user_query || "User Query"}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={12}>
                <Grid container item alignItems="flex-start" spacing={1}>
                  <Grid item>
                    <Avatar>
                      <img
                        src={chatbotresize}
                        alt="bot"
                        height={40}
                        style={{ background: "#fff" }}
                      />
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Paper className="bot-message item">
                      <div className="markdown-content">
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          rehypePlugins={[rehypeHighlight, rehypeRaw]}
                        >
                          {response?.bot_response}
                        </ReactMarkdown>
                        {/* Render the SQL query if it exists */}
                        {response?.sql_query && (
                          <div style={{ marginTop: "10px" }}>
                            <strong>SQL Query:</strong>
                            <pre>{response.sql_query}</pre>
                          </div>
                        )}
                        {response?.is_graph && response?.graph_data && (
                          <GraphComponent graphData={response.graph_data} />
                        )}
                        {response?.is_csv && response?.csv_url && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "10px",
                            }}
                          >
                            <IconButton
                              component="a"
                              href={`${response.csv_url}`}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label="Download CSV"
                              style={{ color: "white" }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ))
        )}
        {/* Ref added to the last message */}
        <div ref={lastMessageRef} />
      </Grid>
      <Grid container className="prompt-container">
        <Grid item md={12}>
          <TextField
            fullWidth
            placeholder="Enter prompt"
            variant="outlined"
            value={prompt}
            multiline
            maxRows={4}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handlePromptSubmit();
              }
            }}
            onChange={handlePromptChange}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handlePromptSubmit}
                    color="primary"
                    aria-label="send"
                    size="large"
                  >
                    <SendIcon fontSize="inherit" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                background: "#fff",
              },
              "& .MuiInputBase-root": {
                overflowY: "auto",
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
