import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: 340,
    width: `calc(100% - 250px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarComponent = ({ open, handleDrawerOpen }) => (
  <AppBar position="fixed" open={open} sx={{ boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2);" }}>
    <Toolbar sx={{ backgroundColor: "#ffffff" }}>
      <IconButton
        onClick={handleDrawerOpen}
        edge="start"
        sx={{
          marginRight: 5,
          ...(open && { display: "none" }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography sx={{
          margin: '0',
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '14px',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          color: "#000000",
          textTransform: "uppercase",
        }} component="div">
          welcome user
        </Typography>
        <Avatar sx={{ bgcolor: "#00b0ff" }}>U</Avatar>
      </Grid>
    </Toolbar>
  </AppBar>
);

export default AppBarComponent;
