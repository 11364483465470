import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, InputLabel, Select, FormHelperText } from '@mui/material';

const RHFSelect = ({ name, label, defaultValue, children, ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} {...other}>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            label={label}
            defaultValue={defaultValue}
          >
            {children}
          </Select>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default RHFSelect;
